<template>
  <b-modal
    ref="consumoCredito"
    size="sm"
    centered
    hide-footer
    hide-header
    @hidden="fechar"
  >
    <div>
      <div class="text-center">
        <font-awesome-icon
          icon="fa-circle-exclamation"
          class="text-warning mt-2"
          size="8x"
        />
        <div class="mt-2">
          <h3>Atenção!</h3>
          <h3>Atualização de {{ tipoRotina }}</h3>
          <div class="my-2">
            Ao confirmar a atualização será consumido um crédito de seu saldo em conta. Deseja concluir a atualização?
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          style="gap:1rem"
        >
          <button
            class="p-60 btn btn-outline-secondary mb-50"
            @click="fechar"
          >
            Não
          </button>
          <button
            class="p-60 btn btn-outline-primary mb-50"
            @click="$emit('atualizar')"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
    tipoRotina: {
      type: String,
      required: true,
    },
  },
  methods: {
    fechar() {
      this.$emit('fechar')
    },
  },
}
</script>
